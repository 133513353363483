.carousel-container {
    position: relative;
    overflow: hidden;
  }
  
  .carousel-item {
    position: absolute;
    top: 0;
    left: 100%;
    transition: left 0.5s ease-in-out; /* Adjust the transition timing as needed */
  }
  
  .carousel-item.active {
    left: 0;
  }
  
  .image-container {
    width: 100%;
    height: 100%;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .carousel-caption {
    position: absolute;
    bottom: 20px; /* Adjust the positioning of the caption */
    left: 20px; /* Adjust the positioning of the caption */
    color: white;
    /* Add your other caption styles here */
  }