@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.slider{
    width: 100%;
    height: 400px;
}

.slide, .slide img{
    width: 100%;
    height: 360px;
    background-size: cover;
    background-position: center;

}

.header{
    margin: 50px auto;
    text-align: center;
    font-size: 40px;
    color: #2a2c38;
    font-weight: 700;
}

.slide{
    transform: scale(.7);
    transition: 0.5s;
    filter: blur(5px);
}
.slide-active{
    opacity: 1;
    transform: scale(1.1);
    filter: blur(0);
}

.arrow{
    font-size: 30px;
    padding: 5px 15px;
    border-radius: 10px;
    box-shadow: 0 0 5px 3px #3c3d3d4a;
    display: flex;
    width: 50px;
    position: absolute;
    top: 130px;
    z-index: 100;
    cursor: pointer;
    color: #2a2c38;
}

.arrow-right{
    right: 20px;
}

.arrow-left {
    left: 20px;
}

.dot{
    margin-top: 20px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0 0 5px 3px #ccc;
}

.dot-active{
    background: #0a3c2c;
    box-shadow: 0 0 10px 4px #ccc;
}

@media screen and (max-width: 768px){
    .header{
        font-size: 7vw;
    }
}